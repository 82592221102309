<template>
  <b-col
    sm="12"
    md="6"
  >
    <div v-if="data">
      <h6><strong>{{ label }}</strong></h6>
      <p
        v-if="!status"
        class="lead"
      >
        {{ data }}
      </p>
      <p
        v-else
        class="lead"
      >
        <b-badge
          v-if="data == '1'"
          variant="success"
        >
          Aktif
        </b-badge>
        <b-badge
          v-else
          variant="danger"
        >
          Pasif
        </b-badge>
      </p>
      <hr>
    </div>
  </b-col>
</template>

<script>
import { BCol, BBadge } from 'bootstrap-vue'

export default {
  name: 'ViewLine',
  components: {
    BCol, BBadge,
  },
  props: {
    label: String,
    data: [String, Number],
    status: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
