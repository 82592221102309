<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <view-line
            label="Kullanıcı ID"
            :data="formData.id"
          />
          <view-line
            label="Adı Soyadı"
            :data="formData.name"
          />
          <view-line
            label="E-Posta"
            :data="formData.email"
          />
          <view-line
            label="Telefon"
            :data="formData.phone"
          />
          <view-line
            label="Dahili"
            :data="formData.internal"
          />
          <view-line
            label="Yönetici"
            :data="formData.manager_name"
          />
          <view-line
            label="Kullanıcı Tipi"
            :data="formData.user_type"
          />
          <view-line
            label="Departman"
            :data="formData.department"
          />
          <view-line
            label="Marka"
            :data="formData.brand"
          />
          <view-line
            label="Durum"
            :data="formData.status"
            :status="true"
          />
        </b-row>
      </b-card-text>
    </b-card>
    <b-button
      variant="primary"
      :to="'/users/edit/' + formData.id"
    >
      Hesabı Güncelle
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BButton,
  BRow, BCol,
} from 'bootstrap-vue'
import ViewLine from '@/views/Core/Users/components/ViewLine'

export default {
  name: 'UsersView',
  components: {
    BForm,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    ViewLine,
  },
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    userData() {
      return this.$store.getters['users/getUser']
    },
  },
  watch: {
    userData(val) {
      this.setFormData(val)
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.$store.dispatch('users/userView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
  },
}
</script>
